<template>
  <div>
    <header-home></header-home>
    <section-news id="news" :posts="posts" class="mb-14 xl:mb-20"></section-news>
    <section-page-preview
      id="information"
      :title="t('information.title')"
      :content="t('information.content')"
      :categories="information.items"
      :link="localePath('information')"
      title-color="text-black"
      border-color="border-green"
      background-color="bg-green"
      background-light-color="bg-green-900"
      class="pb-16 xl:pb-20"
    ></section-page-preview>

    <section-page-preview
      id="prevention"
      :title="t('prevention.title')"
      :content="t('prevention.content')"
      :categories="prevention.items"
      :link="localePath('prevention')"
      title-color="text-black"
      border-color="border-orange"
      background-color="bg-orange"
      background-light-color="bg-orange-900"
      class="pb-16 xl:pb-20"
    ></section-page-preview>

    <section-page-preview
      id="quit"
      :title="t('quit.title')"
      :content="t('quit.content')"
      :categories="quit.items"
      title-color="text-white"
      :link="localePath('quit')"
      border-color="border-dark-blue"
      background-color="bg-dark-blue"
      background-light-color="bg-dark-blue-900"
      class="pb-20 xl:pb-20"
    >
      <template #append>
        <card-quit-cta-home></card-quit-cta-home>
      </template>
    </section-page-preview>
  </div>
</template>

<script>
import CardQuitCtaHome from "~/components/Cards/CardQuitCtaHome.vue";
import HeaderHome from "~/components/Headers/HeaderHome.vue";
import SectionNews from "~/components/Sections/SectionNews.vue";
import SectionPagePreview from "~/components/Sections/SectionPagePreview.vue";


import { setupMeta } from "~/utils/utils";

export default {
  name: "PageHome",
  async setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();

    const nuxtApp = useNuxtApp();

    const { information, prevention, quit, latest_posts } = nuxtApp.$store.state.web;

    defineI18nRoute({
      locales: ["fr"],
      paths: {
        fr: "/",
      },
    });

    await nuxtApp.runWithContext(() => setupMeta( null, information.seo));

    return {
      t,
      locale,
      localePath,
      information,
      prevention,
      quit,
      posts: latest_posts,
    };
  },
  components: {
    HeaderHome,
    SectionNews,
    SectionPagePreview,
    CardQuitCtaHome,
  },
};
</script>

<i18n lang="json">
{
  "fr": {
    "information": {
      "title": "Je m'informe",
      "content": "Le tabagisme est la première cause de mortalité évitable et il tue prématurément un fumeur sur deux. Ce produit a aussi des effets négatifs sur le développement des pays et la santé de la planète."
    },
    "prevention": {
      "title": "Je préviens",
      "content": "Pour aborder le sujet avec les jeunes pour éviter qu’ils commencent à fumer ou à vapoter, il est important de bien comprendre ces problématiques. Tous ensemble pour une génération sans fumée!"
    },
    "quit": {
      "title": "J'arrête",
      "content": "Vous voulez arrêter de fumer? Vous hésitez peut-être encore, aussi. Nous avons ce qu’il vous faut pour vous aider à réussir à vous libérer du tabac par vous-même."
    }
  },
  "en": {}
}
</i18n>
